import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography } from "@material-ui/core";
import MyButton from "../../components/button";
import { Link } from "react-router-dom";
import ConfirmDialog from "../../components/dialog";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../../helpers/notification";
import {
  getCheckouts,
  removeCheckout,
  setCheckout,
} from "../../services/checkouts";
import { initACL } from "../../helpers/acl";
import MyTextField from "../../components/textField";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { ThreeSixtySharp } from "@material-ui/icons";

class Checkouts extends Component {
  state = {
    checkoutsAll: [],
    checkouts: [],
    deleteDialog: false,
    user_id: "",
  };

  componentDidMount = () => {
    /*getCheckouts()
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ checkouts: data, checkoutsAll: data });
      })
      .catch((error) => {});*/
    this.loadCheckouts();
  };

  loadCheckouts() {
    this.setState({ checkouts: [] });
    let user_id = this.state.user_id;
    if (!user_id) user_id = "-1";
    getCheckouts(user_id)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ checkouts: data.checkouts });
      })
      .catch((error) => {});
  }

  handleRemove = (checkoutId) => {
    removeCheckout(checkoutId)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        showInfo(this.props, "تسویه حساب با موفقیت حذف گردید");
        this.setState({
          checkouts: this.state.checkouts.filter(
            (per) => per._id !== checkoutId
          ),
          checkoutsAll: this.state.checkoutsAll.filter(
            (per) => per._id !== checkoutId
          ),
        });
      })
      .catch((error) => {});
  };

  openDeleteDialog = (checkoutId) =>
    this.setState({ deleteDialog: true, deleteId: checkoutId });

  closeDeleteDialog = () => this.setState({ deleteDialog: false, deleteId: 0 });

  handleDeleteDialogConfirm = () => {
    this.handleRemove(this.state.deleteId);
    this.closeDeleteDialog();
  };

  handleSearch = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    const search = event.target.value;
    if (search && search.length > 0)
      this.setState({
        warrants: this.state.warrantsAll.filter((per) =>
          per.name.includes(search)
        ),
      });
    else this.setState({ warrants: this.state.warrantsAll });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setCheckoutPayment = (checkoutId) => {
    const payment_id = this.state.payment_id;

    if (!checkoutId || !payment_id) {
      showError(this.props, "شماره پیگیری را وارد نکرده اید!");
      return;
    }

    this.setState({
      loading: true,
    });

    setCheckout(checkoutId, payment_id)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ذخیره پیش آمد");
          return;
        }
        if (data.error) {
          showError(this.props, "خطا");
          return;
        }

        showInfo(this.props, "تسویه حساب با موفقیت ذخیره گردید");

        this.setState({
          edit: true,
        });
        this.setState({
          loading: false,
          saved: true,
        });

        window.location.reload();
      })
      .catch((error) => {
        showError(this.props, "اشکالی در ذخیره پیش آمد");
      });
  };

  render() {
    const { classes } = this.props;
    const { checkouts } = this.state;
    const {
      user_id,
      payment_id,
      enableEditButton,
      enableDeleteButton,
      enableAddButton,
      search,
      first_name,
      last_name,
      sending,
      success,
      fail,
    } = this.state;

    return (
      <div /*className={classes.memberScroll}*/>
        <ConfirmDialog
          title="حذف حق تقدم"
          content="آیا از حذف مطمئن هستید؟"
          open={this.state.deleteDialog}
          handleConfirm={this.handleDeleteDialogConfirm}
          handleCancel={this.closeDeleteDialog}
        />

        {user_id && (
          <img
            src={require(`../../images/icon_delete_image.png`)}
            style={{ width: 32, cursor: "pointer" }}
            onClick={() => {
              this.setState({ user_id: "" }, () => this.loadCheckouts());
            }}
            title="حذف فیلتر"
          />
        )}

        <Grid
          container
          direction="row-reverse"
          spacing={3}
          style={{ marginBottom: "16px" }}
        >
          {checkouts.map((checkout, index) => (
            <Grid key={index} item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className={classes.memberContent}
              >
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-end"
                  xs={12}
                  spacing={1}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    xs={12}
                    //xs={7}
                    //sm={8}
                    //md={9}
                    //lg={10}
                    spacing={0}
                    style={{ margin: 4 }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        className={classes.memberName}
                        variant="subtitle2"
                      >
                        {dateTimeToPersian(checkout.created_date)}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Link
                          to={{
                            pathname: `/user/search`,
                            userId: checkout.user_id,
                          }}
                        >
                          <img
                            src={require(`../../images/icon_user.png`)}
                            style={{
                              width: "18px",
                            }}
                            //className={classes.editIcon}
                            title="اطلاعات کاربر"
                          />
                        </Link>
                      </Grid>

                      <Grid item>
                        <Typography
                          className={classes.memberName}
                          variant="subtitle2"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "darkblue",
                          }}
                          onClick={() => {
                            this.setState({ user_id: checkout.user_id }, () =>
                              this.loadCheckouts()
                            );
                          }}
                        >
                          {checkout.user_id}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      xs={12}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {checkout.credit.toLocaleString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :اعتبار
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                      xs={12}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {checkout.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :نام
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {checkout.melli}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :کد ملی
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {checkout.birth}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :تاریخ تولد
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <Typography
                          className={classes.memberName}
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {checkout.sheba}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.memberName}>
                          :شماره شبا
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justify="flex-end"
                      spacing={1}
                    >
                      <Grid item xs={11}></Grid>
                      <Grid item xs={1}>
                        <Typography
                          className={classes.memberName}
                          style={{
                            textAlign: "center",
                            color: !checkout.done ? "Red" : "LimeGreen",
                          }}
                        >
                          {!checkout.done ? "تسویه نشده" : "تسویه شده"}
                        </Typography>
                      </Grid>
                    </Grid>

                    {checkout.done && (
                      <Grid
                        item
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item xs={10}>
                          <Typography
                            className={classes.memberName}
                            style={{ textAlign: "right", direction: "rtl" }}
                          >
                            {checkout.payment_id}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.memberName}>
                            :شماره پیگیری پرداخت
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    {!checkout.done && checkout.user.credit < checkout.credit && (
                      <Grid
                        item
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item xs={11}>
                          <Typography
                            className={classes.memberName}
                            variant="body1"
                            style={{
                              textAlign: "right",
                              color: "DarkOrange",
                            }}
                          >
                            اعتبار کاربر کمتر از مبلغ درخواستی است
                          </Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    )}

                    {!checkout.done && (
                      <Grid
                        item
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                        xs={12}
                        style={{ marginTop: "16px" }}
                        alignItems="center"
                      >
                        <Grid item>
                          <img
                            src={require(`../../images/icon_checkout.png`)}
                            className={classes.editIcon}
                            onClick={() =>
                              this.setCheckoutPayment(checkout._id)
                            }
                            style={{ height: "100%" }}
                            title="ثبت پرداخت تسویه"
                          />
                        </Grid>
                        <Grid item>
                          <MyTextField
                            id="payment_id"
                            name="payment_id"
                            value={payment_id}
                            onChange={this.handleChange}
                            classes={classes}
                            margin="dense"
                            variant="outlined"
                            type="text"
                            placeholder="شماره پیگیری پرداخت"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {/*<Grid item xs={3}>
                  <img
                    src={require(`../../images/icon_user.png`)}
                    className={classes.memberImage}
                    alt=""
                  />
                        </Grid>*/}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(Checkouts)
);
