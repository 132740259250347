import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import NewMemberContainer from "../containers/member/newMember";
import { APP_MODE, APP_MODES } from "../constants/settings";
import { getReportImages } from "../services/reportsFace";
import { getReportPlateImages } from "../services/reportsPlate";
import { Grid, Typography } from "@material-ui/core";
import MyButton from "../components/button";

const styles = {
  popup: {
    position: "fixed",
    //width: "100vw",
    //height: "25vh",
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    //top: "50%",
    zIndex: 1000,
    background: "rgba(0,0,0,0.5)"
    //padding: "8px 16px 8px 16px",
    //overflow: "hidden"
  },
  popupInner: {
    position: "absolute",
    left: "10%",
    right: "10%",
    top: "10%",
    bottom: "10%",
    margin: "auto",
    borderRadius: "20px 20px 20px 20px",
    background: "#dddddd",
    padding: "12px"
  },
  close: {
    position: "absolute",
    top: -8,
    left: -8,
    cursor: "pointer",
    height: "32px"
    //marginLeft: 8
  },
  popupImages: {
    //width: "100%",
    //height: "100%"
  },
  popupFlex: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    alignContent: "center"
  },
  popupRow: {
    height: "100%"
  },
  plate: {
    maxHeight: "100%"
  },
  transmission: {
    maxWidth: "100%",
    maxHeight: "100%"
  },
  infoTitle: {
    color: "#0c63c3",
    fontSize: "12px"
  },
  info: {
    color: "white",
    fontSize: "14px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }
};

class ContactImageDialog extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  state = {
    member: null,
    open: false,
    showNewMember: false,
    transmissionImage: null
  };

  //node = React.createRef();

  componentDidUpdate = () => {
    if (this.props.member && !this.state.member) {
      const member = this.props.member;
      const isFromReport = member.box_on_image != null;
      this.setState(
        {
          edit: true,
          member: {
            plate: member.plate ? member.plate.plate : member.plate_char,
            //image_transmission: member.image,
            face_id: !isFromReport ? member.id : member.face_id,
            plate_id: !isFromReport ? member.id : member.plate_id,
            time_epoch_ms: member.first_time
              ? member.first_time
              : member.time_epoch_ms,
            camera_id: member.cam_id ? member.cam_id : member.camera_id,
            permission: member.permission
              ? member.permission
              : member.matching_id != null && member.matching_id > 0,
            name: member.name,
            phone_number: member.phone_number,
            company_name: member.company_name
          }
        },
        () => {
          this.getMemberImage(this.state.member, true);
          if (!this.props.member.image)
            this.getMemberImage(this.state.member, false);
        }
      );
    }
    if (this.props.name && !this.state.name) {
      this.setState({ name: this.props.name });
    }
  };

  componentDidMount() {
    //document.addEventListener("click", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    //document.removeEventListener("click", this.handleOutsideClick, false);
  }

  handleOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }

    this.props.handleCancel();
  }

  showModal = () => {
    this.setState({ open: true });
  };

  hideModal = () => {
    this.setState({ open: false });
  };

  handleShowNewMember = () => {
    this.setState({ showNewMember: true });
  };

  handleClose = () => {
    this.setState({
      showNewMember: false,
      member: null,
      captureImage: null,
      transmissionImage: null
    });
    this.props.handleCancel();
  };

  getMemberImage = (result, isTransmission) => {
    if (APP_MODE == APP_MODES.FACE) {
      getReportImages(result.camera_id, result.time_epoch_ms, result.face_id)
        .then(data2 => {
          if (isTransmission)
            this.setState({
              transmissionImage: `data:image/jpeg;base64,${data2.base64}`
            });
          else
            this.setState({
              captureImage: `data:image/jpeg;base64,${data2.base64}`
            });
        })
        .catch(error2 => { });
    } else if (APP_MODE == APP_MODES.PLATE) {
      getReportPlateImages(
        result.camera_id,
        result.time_epoch_ms,
        result.plate_id,
        isTransmission ? true : false
      )
        .then(data2 => {
          if (isTransmission)
            this.setState({
              transmissionImage: `data:image/jpeg;base64,${data2.base64}`
            });
          else
            this.setState({
              captureImage: `data:image/jpeg;base64,${data2.base64}`
            });
        })
        .catch(error2 => { });
    }
  };

  render() {
    const { classes } = this.props;
    const { showNewMember, member } = this.state;

    if (!this.props.open) {
      return null;
    }
    return (
      <div className={classes.popup}>
        <div className={classes.popupInner} ref={this.node}>
          {showNewMember ? (
            <NewMemberContainer member={member} popup={true} />
          ) : (
            <div className={classes.popupFlex}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%"
                }}
              >
                <img
                  src={this.props.url}
                  className={classes.transmission}
                  alt=""
                />
              </div>
            </div>
          )}
          <img
            src={require(`../images/icon_delete_image.png`)}
            className={classes.close}
            onClick={this.handleClose}
            title="بستن"
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ContactImageDialog);
