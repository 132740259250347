import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography, Card, Paper } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";
import { getReplies } from "../../services/replies";
import { showInfo, showError } from "../../helpers/notification";
import DetailCard from "../../components/detailCard";
import { Link } from "react-router-dom";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { withSnackbar } from "notistack";
import { initACL } from "../../helpers/acl";

class Replies extends Component {
  state = {
    replies: [],
  };

  componentDidMount = async () => {
    this.loadReplies();
  };

  componentDidUpdate(prevProps, prevState) {
    const result = initACL(this.state.initACL, 0);
    if (result.length > 0) {
      this.setState({
        enableUserInfoButton: result[3],
        initACL: true,
      });
    }
  }

  loadReplies() {
    this.setState({ replies: [] });
    getReplies()
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ replies: data.replies });
      })
      .catch((error) => { });
  }

  render() {
    const { classes } = this.props;
    const { replies, enableUserInfoButton } = this.state;
    const { cameras, captures, capturesCount, src } = this.props;

    return (
      <Grid container direction="row" /*className={classes.liveContainer}*/>
        {replies.map((replyMonth, index) => (
          <Grid container direction="row">
            <Grid
              container
              justify="center"
              style={{ marginTop: 48, marginBottom: 16 }}
            >
              <Grid item>
                {replyMonth && replyMonth[0] && (
                  <Typography className={classes.memberName} variant="h6">
                    {`ماه ${replyMonth[0].month}`}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="stretch"
              alignContent="stretch"
            >
              {replyMonth.map(
                (reply, index) =>
                  reply._id && (
                    <Grid key={index} item xs={12}>
                      <Card>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          className={classes.memberContent}
                        >
                          <Grid
                            item
                            container
                            direction="row"
                            xs={11}
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid
                              item
                              container
                              direction="column"
                              alignItems="flex-end"
                              xs={12}
                              //xs={7}
                              //sm={8}
                              //md={9}
                              //lg={10}
                              spacing={0}
                              style={{ margin: 4 }}
                            >
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.memberName}
                                  variant="subtitle2"
                                >
                                  {reply.supporter_username}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Typography
                                  className={classes.memberName}
                                  variant="subtitle2"
                                >
                                  {reply.supporter_name}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Typography
                                  className={classes.memberName}
                                  variant="body1"
                                  style={{
                                    textAlign: "right",
                                    direction: "rtl",
                                  }}
                                >
                                  {reply.count} پاسخ
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Typography
                                  className={classes.memberName}
                                  variant="subtitle2"
                                  style={{
                                    textAlign: "right",
                                    direction: "rtl",
                                  }}
                                >
                                  میانگین زمان پاسخ {reply.delay} دقیقه
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <img
                              src={require(`../../images/inbox.png`)}
                              className={classes.memberImage}
                              style={{
                                maxWidth: "100%",
                              }}
                              alt=""
                              onError={() => this.noDefaultImage(index)}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(withSnackbar(Replies));
