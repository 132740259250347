const styles = {
  replied: {
    color: "white",
    fontSize: "12px",
    backgroundColor: "#980098",
    borderRadius: "4px",
    marginTop: "8px",
    paddingLeft: "4px",
    paddingRight: "4px",
    //overflowWrap: "break-word"
  },
  web: {
    color: "white",
    fontSize: "12px",
    backgroundColor: "#e10000",
    borderRadius: "4px",
    marginTop: "8px",
    paddingLeft: "4px",
    paddingRight: "4px",
    //overflowWrap: "break-word"
  },
  replyGrid: {
    color: "black",
    fontSize: "10px",
    backgroundColor: "#DFF1FE",
    borderRadius: "8px",
    marginTop: "4px",
    //marginRight: "16px",
    //marginLeft: "32px",
    marginBottom: "4px",
    //overflowWrap: "break-word"
  },
};

export default styles;
