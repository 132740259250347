import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import MyTextField from "../components/textField";
import { showInfo, showError } from "../helpers/notification";
import { authAdmin } from "../services/admins";
import { withSnackbar } from "notistack";
import { Redirect } from "react-router-dom";
import { setUser, getUser } from "../helpers/user";
import { connect } from "react-redux";
import { saveUser } from "../reducers/user";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const styles = {
  navigation: {
    overflow: "hidden",
  },
  login: {
    paddingTop: "24px",
    paddingBottom: "16px",
  },
  loginButton: {
    marginTop: "16px",
  },
};

const LoginButton = styled(Button)`
  width: 220px;
  background: linear-gradient(to right, #730909 0%, #c00c0c 51%, #730909 100%);
  color: white;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px #542626;
  .MuiButton-contained {
    border: 10px solid #542626;
  }
  & .MuiButton-label {
    color: white;
  }
`;

class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  componentDidMount = () => {
    const user = getUser();
    if (user) {
      this.setState({
        logged: true,
      });
      this.props.saveUser(user);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleLogin = () => {
    const username = this.state.username;
    const password = this.state.password;
    if (!username || !password) {
      this.setState({
        username_error: !username,
        password_error: !password,
      });
      return;
    }

    this.setState({
      loading: true,
      username_error: false,
      password_error: false,
    });

    authAdmin(username, password)
      .then((data) => {
        if (data.result == false) {
          showError(this.props, "نام کاربری یا کلمه عبور اشتباه است");
          return;
        }
        setUser(data.admin);
        showInfo(this.props, "ورود با موفقیت انجام شد");
        this.setState({
          loading: false,
          logged: true,
        });
        this.props.saveUser(data.admin);
      })
      .catch((error) => {
        console.log(error);
        showError(this.props, "اشکالی در ورود پیش آمد");
      });
  };

  handleEnter = (event) => {
    if (event.key === "Enter") {
      this.handleLogin();
    }
  };

  render() {
    const { classes } = this.props;
    const { username, password, username_error, password_error } = this.state;

    if (this.state.logged === true) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <Helmet>
          <title>بورس</title>
        </Helmet>
        <Grid container>
          <Grid item>{/*<div className={classes.header}></div>*/}</Grid>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            //style={{ position: "absolute", top: "40%" }}
            className={classes.login}
          >
            <Grid item>
              <MyTextField
                placeholder="نام کاربری"
                name="username"
                value={username}
                onChange={this.handleChange}
                margin="dense"
                variant="outlined"
                error={username_error}
                onKeyPress={this.handleEnter}
                dir="ltr"
                center="true"
              />
            </Grid>
            <Grid item>
              <MyTextField
                placeholder="کلمه عبور"
                name="password"
                value={password}
                onChange={this.handleChange}
                margin="dense"
                variant="outlined"
                error={password_error}
                type="password"
                onKeyPress={this.handleEnter}
                dir="ltr"
                center="true"
              />
            </Grid>
            <Grid item>
              <LoginButton
                className={classes.loginButton}
                variant="contained"
                onClick={this.handleLogin}
              >
                ورود
              </LoginButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(Login)));
