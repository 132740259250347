import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import styles from "../../styles/main";
import stylesMembers from "../../styles/members";
import { Typography, Card, Paper } from "@material-ui/core";
import BoxTitle from "../../components/boxTitle";
import classNames from "classnames";
import { unixToTime } from "../../helpers/unix";
import { APP_MODE, APP_MODES } from "../../constants/settings";
import MemberInfoDialog from "../../components/memberInfoDialog";
import { getInbox } from "../../services/inbox";
import { showInfo, showError } from "../../helpers/notification";
import DetailCard from "../../components/detailCard";
import { Link } from "react-router-dom";
import { dateToPersian, dateTimeToPersian } from "../../helpers/dateToPersian";
import { withSnackbar } from "notistack";
import { initACL } from "../../helpers/acl";

class InboxList extends Component {
  state = {
    inboxList: [],
    user_id: ""
  };

  componentDidMount = async () => {
    this.loadInbox();
  };

  componentDidUpdate(prevProps, prevState) {
    const result = initACL(this.state.initACL, 0);
    if (result.length > 0) {
      this.setState({
        enableUserInfoButton: result[3],
        initACL: true
      });
    }
  }

  loadInbox() {
    this.setState({ inboxList: [] });
    let user_id = this.state.user_id;
    if (!user_id) user_id = "-1";
    getInbox(user_id)
      .then(data => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        this.setState({ inboxList: data.inbox });
      })
      .catch(error => { });
  }

  render() {
    const { classes } = this.props;
    const { inboxList, user_id, enableUserInfoButton } = this.state;
    const { cameras, captures, capturesCount, src } = this.props;

    return (
      <Grid container direction="row" /*className={classes.liveContainer}*/>
        {user_id && (
          <img
            src={require(`../../images/icon_delete_image.png`)}
            style={{ width: 32, cursor: "pointer" }}
            onClick={() => {
              this.setState({ user_id: "" }, () => this.loadInbox());
            }}
            title="حذف فیلتر"
          />
        )}
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="stretch"
          alignContent="stretch"
        >
          {inboxList.map((inbox, index) => (
            <Grid key={index} item xs={12}>
              <Card>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  className={classes.memberContent}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    xs={11}
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="flex-end"
                      xs={12}
                      //xs={7}
                      //sm={8}
                      //md={9}
                      //lg={10}
                      spacing={1}
                      style={{ margin: 4 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="subtitle2"
                        >
                          {dateTimeToPersian(inbox.created_date)}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        {enableUserInfoButton && (
                          <Grid item>
                            <Link
                              to={{
                                pathname: `/user/search`,
                                userId: inbox.user_id
                              }}
                            >
                              <img
                                src={require(`../../images/icon_user.png`)}
                                style={{
                                  width: "18px"
                                }}
                                //className={classes.editIcon}
                                title="اطلاعات کاربر"
                              />
                            </Link>
                          </Grid>
                        )}
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="subtitle2"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "darkblue"
                            }}
                            onClick={() => {
                              this.setState({ user_id: inbox.user_id }, () =>
                                this.loadInbox()
                              );
                            }}
                          >
                            {inbox.user_id}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.memberName}
                          >
                            {inbox.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.memberName}
                          >
                            :عنوان
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.memberName}
                          variant="body2"
                          style={{ textAlign: "right", direction: "rtl" }}
                        >
                          {inbox.message}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {inbox.vote_down_count}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <img
                            style={{
                              width: "16px"
                            }}
                            src={require(`../../images/thumb_down.png`)}
                          />
                        </Grid>

                        <Grid item>
                          <img
                            style={{
                              width: "16px",
                              marginLeft: "16px"
                            }}
                            src={require(`../../images/thumb_up.png`)}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            className={classes.memberName}
                            variant="body2"
                          >
                            {inbox.vote_up_count}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <img
                      src={require(`../../images/inbox.png`)}
                      className={classes.memberImage}
                      style={{
                        maxWidth: "100%"
                      }}
                      alt=""
                      onError={() => this.noDefaultImage(index)}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}

//export default withStyles({ ...styles, ...stylesMembers })(withSnackbar(InboxList));
export default withStyles(styles)(withSnackbar(InboxList));
