import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import styles from "../styles/main";
import stylesMembers from "../styles/members";
import MyButton from "../components/button";
import { InputRow } from "../components/inputRow";
import { withSnackbar } from "notistack";
import { showInfo, showError } from "../helpers/notification";
import { Link, Redirect } from "react-router-dom";
import {
  getWarrant,
  addWarrant,
  clearWarrantsCache,
} from "../services/warrants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";

class NewWarrant extends Component {
  state = {};

  componentDidMount = () => {
    if (this.props.match.params.id) {
      var warrantId = this.props.match.params.id;
      if (!warrantId) return;
      getWarrant(warrantId)
        .then((data) => {
          if (data.forbidden === true) {
            showError(this.props, "عملیات غیر مجاز");
            return;
          }
          this.setState({
            edit: true,
            warrant_id: warrantId,
            warrant: data,
            admin_id: data.admin_id,
            name: data.name,
            start_date: data.start_date,
            end_date: data.end_date,
            type: data.type,
            increase_percent: data.increase_percent,
            show: data.show,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /*componentDidUpdate = () => {
    if (this.props.templateAnswer && !this.state.templateAnswer) {
      const templateAnswer = this.props.templateAnswer;
      this.setState({
        edit: true,
        templateAnswer: templateAnswer,
        order: templateAnswer.order,
        title: templateAnswer.title,
        answer: templateAnswer.answer,
      });
    }
  };*/

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSave = () => {
    const edit = this.state.edit;
    const name = this.state.name.trim();
    const start_date = this.state.start_date.trim();
    const end_date = this.state.end_date.trim();
    const type = this.state.type;
    const increase_percent = this.state.increase_percent;
    const show = this.state.show;
    const hide = this.state.hide;

    if (!name || !start_date || !end_date) {
      this.setState({
        name_error: !name,
        start_date_error: !start_date,
        end_date_error: !end_date,
      });
      return;
    }

    this.setState({
      loading: true,
      name_error: false,
      start_date_error: false,
      end_date_error: false,
    });

    const warrant = {
      name,
      start_date,
      end_date,
      type,
      increase_percent,
    };

    if (hide != null) warrant.show = !hide;
    else if (show != null) warrant.show = show;

    console.log("hide: ", hide);
    console.log("show: ", show);
    console.log("warrant.show: ", warrant.show);

    if (this.state.edit) {
      warrant._id = this.state.warrant._id;
    }

    addWarrant(warrant)
      .then((data) => {
        if (data.forbidden === true) {
          showError(this.props, "عملیات غیر مجاز");
          return;
        }
        if (data.result === false) {
          showError(this.props, "اشکالی در ذخیره پیش آمد");
          return;
        }
        if (data.error) {
          showError(this.props, "خطا");
          return;
        }

        showInfo(this.props, "حق تقدم با موفقیت ذخیره گردید");
        if (!this.state.edit && data.id)
          this.setState({
            warrant: { ...warrant, id: data.id },
            edit: true,
          });
        this.setState({
          loading: false,
          saved: true,
        });

        clearWarrantsCache()
          .then((data) => {})
          .catch((error) => {});
      })
      .catch((error) => {
        showError(this.props, "اشکالی در ذخیره پیش آمد");
      });
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      start_date,
      end_date,
      type,
      increase_percent,
      show,
      hide,
      edit,
      name_error,
      start_date_error,
      end_date_error,
      sending,
      success,
      fail,
    } = this.state;

    if (this.state.saved === true) {
      return <Redirect to="/data" />;
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <div className={classes.memberScroll}>
        <Grid container direction="column">
          <InputRow
            id="name"
            value={name}
            onChange={this.handleChange}
            label="نماد حق تقدم"
            classes={classes}
            error={name_error}
          />
          <InputRow
            id="start_date"
            value={start_date}
            onChange={this.handleChange}
            label="تاریخ شروع"
            classes={classes}
            error={start_date_error}
          />
          <InputRow
            id="end_date"
            value={end_date}
            onChange={this.handleChange}
            label="تاریخ پایان"
            classes={classes}
            error={end_date_error}
          />
          <InputRow
            id="type"
            value={type}
            onChange={this.handleChange}
            label="نوع"
            classes={classes}
          />
          <InputRow
            id="increase_percent"
            value={increase_percent}
            onChange={this.handleChange}
            label="درصد افزایش سرمایه"
            classes={classes}
          />
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
            spacing={3}
          >
            <Grid item xs={10} style={{ textAlign: "right" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.handleCheck}
                    id="hide"
                    name="hide"
                    checked={hide != null ? hide : show != null ? !show : false}
                  />
                }
                label="پنهان"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography align="left" className={classes.label}></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <Link to="/data" style={{ textDecoration: "none" }}>
              <MyButton className={classes.new} variant="contained">
                بازگشت
              </MyButton>
            </Link>
          </Grid>
          <Grid item>
            <MyButton
              className={classes.new}
              variant="contained"
              onClick={this.handleSave}
            >
              ذخیره
            </MyButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles({ ...styles, ...stylesMembers })(
  withSnackbar(NewWarrant)
);
